import React, {useState} from 'react';
import CustomSoftwareDevelopment from '../CustomSoftwareDevelopment';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import Section from '../Section';
import {graphql, useStaticQuery} from 'gatsby';
import styles from './AboutClients.module.scss';
import Typography from '@material-ui/core/Typography';
import BigHorizontalCard from '../BigHorizontalCard';
import {
  ABOUT_CLIENTS,
  ABOUT_PARTNERS,
  PARTNERS_MAP,
} from '../../constants/about-clients';
import ExpandingCard from "../ExpandingCard";

const AboutClients = () => {
  const [currentChosenPartner, setCurrentChosenPartner] = useState(null);
  const {
    clientsIcons,
    partnersIcons,
    background,
    closeIcon,
  } = useStaticQuery(graphql`
    query {
      clientsIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutClients" }
          relativeDirectory: { eq: "clients" }
        }
      ) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 376, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      partnersIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutClients" }
          relativeDirectory: { eq: "partners" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutClients" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      closeIcon: allFile(
        filter: {
          sourceInstanceName: { eq: "aboutClients" }
          name: { eq: "close" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  const clientsIconsSorted = clientsIcons.nodes.sort((a, b) => a.name - b.name);
  const partnersIconsSorted = partnersIcons.nodes.sort(
    (a, b) => a.name - b.name
  );
  return (
    <Section backgroundColor="#FFF" height="auto">
      <div className={styles['clients-and-testimonials']}>
        <InnerPagesUpperBanner
          text={['Clients and testimonials']}
          description={[
            'We have helped 100+ companies transform',
            'their business with top-notch tech solutions',
          ]}
          bg={background.nodes[0].publicURL}
        />
        <div className={styles['clients-container']}>
          <Typography
            variant="h1"
            align="center"
            color="secondary"
            className={styles['title']}
          >
            Clients
          </Typography>
          {clientsIconsSorted.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <div className={styles['card-container']}>
                  <BigHorizontalCard
                    node={item}
                    index={i}
                    data={ABOUT_CLIENTS}
                    reverse
                    borderBottom
                  />
                </div>
                <div className={styles['card-container-mobile']}>
                  <ExpandingCard img={item.publicURL}
                                 noIndicator
                                 clientCard
                  >
                    {{
                      expandedView: (
                        <div className={'expanding-card-container'}>
                          <hr
                            style={{
                              background: '#DFE4ED'
                            }}
                          />
                          <Typography variant="h3" align={'center'}>{ABOUT_CLIENTS[i].title}</Typography>
                          <Typography variant="subtitle1" align={'center'}>{ABOUT_CLIENTS[i].addr}</Typography>
                          {ABOUT_CLIENTS[i].text}
                        </div>
                      )
                    }}
                  </ExpandingCard>
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <div className={styles['partners-container']}>
          <Typography
            variant="h1"
            align="center"
            color="secondary"
            className={styles['title']}
          >
            Partners
          </Typography>
          <div className={styles['partners-logos-container']}>
            {partnersIconsSorted.map((item, ind) => (
              <React.Fragment key={ind}>
                <div
                  role={'button'}
                  className={styles['card-container']}
                  onClick={() => {
                    setCurrentChosenPartner({
                      image: item,
                      text: ABOUT_PARTNERS[PARTNERS_MAP[item.name]],
                    });
                    document.body.style.overflow = 'hidden';
                  }}
                >
                  <img src={item.publicURL} alt={item.name}/>
                </div>
                <div className={styles['card-container-mobile']}>
                  <ExpandingCard clientCard
                                 img={item.publicURL}
                                 noIndicator
                  >
                    {{
                      expandedView: (
                        <div className={'expanding-card-container'}>
                          <hr
                            style={{
                              background: '#DFE4ED'
                            }}
                          />
                          {ABOUT_PARTNERS[PARTNERS_MAP[item.name]]}
                        </div>)
                    }}
                  </ExpandingCard>
                </div>
              </React.Fragment>
            ))}
          </div>
          {currentChosenPartner && (
            <div
              role={'button'}
              className={styles['pop-up-container']}
              onClick={ev => {
                if (ev.target === ev.currentTarget) {
                  setCurrentChosenPartner(null);
                  document.body.style.overflow = 'auto';
                }
              }}
            >
              <div>
                <img
                  role={'button'}
                  className={styles['close']}
                  src={closeIcon.nodes[0].publicURL}
                  alt="close"
                  onClick={() => {
                    setCurrentChosenPartner(null);
                    document.body.style.overflow = 'auto';
                  }}
                />
                <img
                  className={styles['logo']}
                  src={currentChosenPartner.image.publicURL}
                  alt={currentChosenPartner.image.name}
                />
                {currentChosenPartner.text &&
                currentChosenPartner.text.map((i, ind) => (
                  <Typography key={ind}>{i}</Typography>
                ))}
              </div>
            </div>
          )}
        </div>
        <CustomSoftwareDevelopment/>
      </div>
    </Section>
  );
};

export default AboutClients;
