export const CUSTOM_SOFTWARE_DEVELOPMENT = [
    {
        text: 'Custom software development',
        icon: 1,
        link: '/services/software-development'
    },
    {
        text: 'Migration to Google Cloud',
        icon: 2,
        link: '/expertise/google-cloud-platform/migration-to-google-cloud'
    },
    {
        text: 'CDAP Support',
        icon: 3,
        link: '/services/cdap-support'
    },
    {
        text: 'Big data analytics, ML&AI',
        icon: 4,
        link: '/expertise/big-data-analytics'
    },
    {
        text: 'Google Cloud Data Fusion',
        icon: 5,
        link: '/expertise/google-cloud-platform/google-cloud-data-fusion'
    },
    {
        text: 'IoT Services',
        icon: 6,
        link: '/expertise/iot-development'
    }

]
