import classNames from 'classnames';
import React from 'react';
import styles from './InnerPagesUpperBanner.module.scss';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const InnerPagesUpperBanner = ({ bg, buttons, className, description, descriptionClassName, text, textClassName }) => {
  return (
    <div className={`${styles.main} ${className}`}>
      <img className={styles.main__image} src={bg} alt="background" />
      <Typography
        className={classNames(styles.main__text, textClassName, {
          [styles['with-description']]: buttons || description,
          [styles['without-description']]: !(buttons || description)
        })}
        variant={'h1'}
      >
        {text.map(line => (
          <React.Fragment key={line}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Typography>
      {description && (
        <Typography
          className={classNames(styles.main__description, descriptionClassName, {
            [styles['with-buttons']]: buttons,
            [styles['without-buttons']]: !buttons
          })}
          variant="h3"
        >
          {description.map(line => (
            <React.Fragment key={line}>
              {line}
              <br />
            </React.Fragment>
          ))}
          {buttons && <div className={styles.main__buttons}>
            {buttons.map(button => {
              const {caption, classes, color, endIconRenderer, id, onClick, startIconRenderer, size, variant} = button;
              return <Button
                classes={classes}
                color={color}
                {...typeof endIconRenderer === 'function' && {endIcon: endIconRenderer()}}
                id={id}
                key={id}
                onClick={onClick}
                {...typeof startIconRenderer === 'function' && {startIcon: startIconRenderer()}}
                size={size}
                variant={variant}
              >
                {caption}
              </Button>;
            })}
          </div>}
        </Typography>
      )}
    </div>
  );
};

export default InnerPagesUpperBanner;
