import React from 'react';
import Layout from '../components/Layout';
import useSiteMetadata from '../hooks/use-site-metadata';
import AboutClients from '../components/AboutClients';
import {graphql, useStaticQuery} from "gatsby";

const AboutClientsTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
        query {
            bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "clients-and-partners" } }) {
                nodes {
                    name
                    publicURL
                }
            }
        }
    `);
    return (
        <Layout title="CyberVision - Clients and Testimonials"
                location={location}
                description={siteSubtitle}
                socialImage={bg.nodes[0].publicURL} >
            <AboutClients />
        </Layout>
    );
};

export default AboutClientsTemplate
