import React from 'react';
import Section from '../Section';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './CustomSoftwareDevelopment.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { CUSTOM_SOFTWARE_DEVELOPMENT } from '../../constants/custom-software-development';
import HorizontalCard from '../HorizontalCard';
import GatsbyLink from "gatsby-link";

const CustomSoftwareDevelopment = () => {
  const { gears, mobileGears, CSDIcons } = useStaticQuery(graphql`
    query {
      gears: allFile(filter: { sourceInstanceName: { eq: "gears" } }) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
      mobileGears: allFile(filter: { sourceInstanceName: { eq: "gears" } relativeDirectory: { eq: "mobile" } }) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
      CSDIcons: allFile(
        filter: { sourceInstanceName: { eq: "customSoftwareDevelopment" } }
      ) {
        nodes {
          relativePath
          name
          publicURL
        }
      }
    }
  `);
  const gearsMap = gears.nodes.reduce(
    (acc, i) => ({ ...acc, [i.name]: i.publicURL }),
    {}
  );
  return (
    <Section
      className={styles['section']}
      backgroundColor="#E9F2FB"
      height="auto"
    >
      <div className={styles['custom-software-development']}>
        <img className={`${styles['gear-mobile']} ${styles['left']}`} src={mobileGears.nodes.find(({name}) => name ==='left').publicURL} alt="gear" />
        <img className={`${styles['gear-mobile']} ${styles['right']}`} src={mobileGears.nodes.find(({name}) => name ==='right').publicURL} alt="gear" />
        <div className={styles['text-container']}>
          <Typography className={styles['caption']} variant={'body2'}>
            CUSTOM SOFTWARE DEVELOPMENT
          </Typography>
          <Typography variant="caption" className={styles['title']}>
            Let's take your business
            <br />
            to a whole <span className={styles['new-level']}> new level</span>
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            color="secondary"
            className={styles['button']}
            href={'/contact-us'}
            id={'footer_estimate-button'}
          >
            Contact us
          </Button>
        </div>
        <div className={styles['picture-holder']}>
        <div className={styles['picture-container']}>
          <div className={styles['blue-part']}>
            <div className={styles['white-dot']}/>
            <div className={styles['white-dot']}/>
            <div className={styles['white-dot']}/>
          </div>
          <div className={styles['white-part']}>
            <div className={`${styles.gear} ${styles.left}`}>
              <img src={gearsMap['bigger-gear']} alt="gear" />
            </div>
            <div className={`${styles.gear} ${styles.right}`}>
              <img src={gearsMap['smaller-gear']} alt="gear" />
            </div>
            {CUSTOM_SOFTWARE_DEVELOPMENT.map(item => {
              return (
                <div key={item.icon} className={styles['card-container']}>
                  <GatsbyLink to={item.link}>
                  <HorizontalCard
                    title={item.text}
                    icon={CSDIcons.nodes[item.icon - 1].publicURL}
                    padding={'20px 26px 15px 26px'}
                    imgWidth={'48px'}
                    smallCard
                    hasBoxShadow
                    height="inherit"
                  />
                  </GatsbyLink>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      </div>
    </Section>
  );
};

export default CustomSoftwareDevelopment;
